//
// Theme style
//

// Initialize
@import "init";

// Components
@import "./core/components/components";
@import "components/components";

// Layout
@import "./core/layout/base/layout";
@import "layout/layout";

#root {
  .container {
    min-width: 1000px;
  }
}

#editor-title-textarea {
  @extend %heading;
  font-size: 2em;
}

.generated-image-card {
  transition: 0.3s ease-in-out !important;

  .image-action-icon {
    transition: opacity 0.3s ease-in !important;
    opacity: 0;
  }
}

.generated-image-card:hover {
  transform: scale(1.08);

  .image-action-icon {
    opacity: 1;
  }
}

.apexcharts-legend-text {
  color: var(--bs-text-gray-800) !important;
}

.editor-setting-popover {
  border-radius: 0.475rem;
  background-color: var(--bs-menu-dropdown-bg-color);
  box-shadow: var(--bs-menu-dropdown-box-shadow);
  z-index: 99;
  margin-top: -175px;
  margin-left: 20px;
}

#editorjs {
  font-size: 14px !important;

  .ce-block__content,
  .ce-toolbar__content {
    max-width: 80% !important;
    @media (min-width: 1600px) {
      max-width: 60% !important;
    }
  }
  .cdx-block {
    max-width: 100% !important;
  }

  .image-tool__caption {
    display: none !important;
  }

  .ce-popover__custom-content {
    padding-bottom: 12px !important;
    margin-bottom: 12px !important;
    border-bottom: 1px solid var(--bs-border-color) !important;
  }

  .ce-popover__items {
    display: flex !important;
    flex-direction: column !important;

    div[data-item-name^="beSyncTool"] {
      order: -1 !important;
    }

    div[data-item-name="paragraph"] {
      position: relative;
      box-sizing: border-box;
      margin-top: 12px !important;
      padding: 12px 0px 0px !important;
      border-top: 1px solid var(--bs-border-color) !important;
      background-clip: content-box !important;
      border-radius: 0px;
      div {
        margin: 3px !important;
      }
    }
    div[data-item-name="paragraph"]::before {
      content: "";
      position: absolute;
      top: 12px;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 6px;
      z-index: -1;
    }
    div[data-item-name="paragraph"]:hover {
      background-color: unset !important;
    }
    div[data-item-name="paragraph"]:hover::before {
      background-color: var(--bs-menu-link-bg-color-hover) !important;
    }
  }
}

.cdx-notifies {
  z-index: 9999 !important;
}

@include color-mode(dark) {
  ::selection {
    background-color: var(--bs-gray-500) !important;
  }
  .cdx-notify {
    background: var(--bs-menu-link-bg-color-hover) !important;
    box-shadow: var(--bs-menu-dropdown-box-shadow) !important;
  }
  .cdx-notify__cross {
    margin-right: 8px;
  }
  .ce-code__textarea {
    background: var(--bs-input-solid-bg-focus) !important;
    border-color: var(--bs-border-color) !important;
  }
  .ce-block--selected .ce-block__content {
    background-color: var(--bs-gray-500) !important;
  }
  .ce-toolbar__plus {
    color: unset !important;
  }
  .ce-toolbar__settings-btn {
    color: unset !important;
  }
  .ce-toolbar__settings-btn:hover {
    background-color: var(--bs-menu-link-bg-color-hover) !important;
  }
  .ce-toolbar__plus:hover {
    background-color: var(--bs-menu-link-bg-color-hover) !important;
  }
  .ce-inline-toolbar {
    background-color: var(--bs-menu-link-bg-color-hover) !important;
    border-color: var(--bs-border-color) !important;
  }
  .ce-inline-tool:hover {
    background-color: var(--bs-gray-400) !important;
  }
  .ce-inline-toolbar__dropdown:hover {
    background-color: var(--bs-gray-400) !important;
  }
  .ce-popover {
    background-color: var(--bs-menu-dropdown-bg-color) !important;
    box-shadow: var(--bs-menu-dropdown-box-shadow) !important;
    border: unset !important;
  }
  .ce-conversion-toolbar {
    background-color: var(--bs-menu-dropdown-bg-color) !important;
    box-shadow: var(--bs-menu-dropdown-box-shadow) !important;
    border: unset !important;
  }
  .ce-conversion-tool__icon {
    background-color: var(--bs-menu-dropdown-bg-color);
    box-shadow: 0 0 0 1px var(--bs-menu-link-bg-color-hover) !important;
  }
  .ce-conversion-tool:hover {
    background-color: var(--bs-menu-link-bg-color-hover) !important;

    .ce-popover-item__icon {
      background-color: var(--bs-menu-dropdown-bg-color) !important;
    }
  }
  .cdx-search-field {
    background: var(--bs-gray-200) !important;
    border-color: var(--bs-border-color) !important;
  }
  .cdx-search-field input {
    caret-color: var(--bs-gray-800) !important;
    color: white !important;
  }
  .ce-popover-item {
    color: unset !important;
  }
  .ce-popover-item__icon {
    background-color: var(--bs-menu-dropdown-bg-color);
    box-shadow: 0 0 0 1px var(--bs-menu-link-bg-color-hover) !important;
  }
  .ce-popover-item:hover:not(.ce-popover-item--no-hover):not(.ce-popover-item--confirmation) {
    background-color: var(--bs-menu-link-bg-color-hover) !important;

    .ce-popover-item__icon {
      background-color: var(--bs-menu-dropdown-bg-color) !important;
    }
  }
  .ce-popover-item--disabled {
    color: var(--color-text-secondary) !important;
  }

  .image-tool--loading .image-tool__image {
    border: 1px solid var(--bs-border-color) !important;
    background-color: var(--bs-input-solid-bg) !important;
  }

  .cdx-button {
    border: 1px solid var(--bs-border-color) !important;
    background: var(--bs-secondary) !important;
  }
  .cdx-button:hover {
    background: var(--bs-secondary-active) !important;
  }
}

.trend-magic {
  --cut: 0.1em;
  --active: 0;
  --transition: 0.25s;
  --spark: 2.2s;

  @keyframes flip {
    to {
      rotate: 180deg;
    }
  }

  @keyframes rotate {
    to {
      transform: rotate(90deg);
    }
  }

  .spark {
    position: absolute;
    inset: 0;
    border-radius: 100px;
    rotate: 0deg;
    overflow: hidden;
  }

  .spark:before {
    content: "";
    position: absolute;
    width: 200%;
    aspect-ratio: 1;
    top: 0%;
    left: 50%;
    z-index: -1;
    translate: -50% -15%;
    rotate: 0;
    transform: rotate(-90deg);
    opacity: calc((var(--active)) + 0.4);
    background: conic-gradient(from 0deg, transparent 0 340deg, white 360deg);
    transition: opacity var(--transition);
    animation: rotate var(--spark) linear infinite both;
  }

  .spark:after {
    content: "";
    position: absolute;
    inset: var(--cut);
    border-radius: 100px;
  }

  .backdrop {
    position: absolute;
    inset: var(--cut);
    background: var(--bs-body-bg);
    border-radius: 100px;
    transition: all var(--transition);
  }

  .trend-shadow {
    box-shadow: 0 0 calc(var(--active) * 6em) calc(var(--active) * 3em)
        hsl(260 97% 61% / 0.75),
      0 0.05em 0 0
        hsl(260 calc(var(--active) * 97%) calc((var(--active) * 50%) + 30%))
        inset,
      0 -0.05em 0 0 hsl(260 calc(var(--active) * 97%) calc(var(--active) * 60%))
        inset;
  }

  @supports (selector(:has(:is(+ *)))) {
    .trend-magic:has(#trend-option:is(:hover, :focus-visible)) {
      --active: 1;
      --play-state: running;
    }
  }

  --trend-shadow: 0 0 calc(1 * 1em) calc(1 * 0.2em) hsl(260 97% 61% / 0.75),
    0 0.05em 0 0 hsl(260 calc(1 * 97%) calc((1 * 50%) + 30%)) inset,
    0 -0.05em 0 0 hsl(260 calc(1 * 97%) calc(1 * 60%)) inset;

  #trend-option {
    transition: all var(--transition);
  }

  #trend-option:hover {
    scale: 1.05;

    > :first-child {
      background-color: rgba(107, 34, 252, 0.7) !important;
    }
  }

  .trend-bg {
    background: radial-gradient(
        40% 50% at center 100%,
        hsl(270 calc(1 * 97%) 72% / 1),
        transparent
      ),
      radial-gradient(
        80% 100% at center 120%,
        hsl(260 calc(1 * 97%) 70% / 1),
        transparent
      ),
      hsl(260 calc(1 * 97%) calc((1 * 44%) + 12%));
  }

  .trend-option {
    cursor: pointer;
    transition: all var(--transition) ease-in-out;
    box-shadow: rgba(123, 59, 252, 0.75) 0px 0px 0.2em 0.01em,
      rgb(188, 155, 253) 0.2px 0.05em 1px 0px inset,
      rgb(120, 54, 252) -0.2px -0.05em 1px 0px inset;
    border-radius: 100px;

    &:hover {
      @extend .trend-bg;

      .spark-icon {
        color: white;
      }
    }

    .white-spark {
      color: white;
    }
  }

  .add-link-button {
    &:hover {
      border-color: rgb(188, 155, 253) !important;
      color: rgb(188, 155, 253) !important;

      .svg-icon {
        color: rgb(188, 155, 253) !important;
      }
    }
  }

  .title-option {
    scale: 0.96;
    transition: all var(--transition);

    &:hover {
      scale: 1;
      border-color: rgba(123, 59, 252, 0.75) !important;
    }

    &.with-active {
      scale: 1;
      border-color: rgba(123, 59, 252, 0.75);
    }

    .form-check-input:checked {
      background-color: rgba(123, 59, 252, 0.75) !important;
    }
  }

  .create-content:hover {
    box-shadow: rgba(123, 59, 252, 0.75) 0px 0px 1em 0.3em,
      rgb(188, 155, 253) 0.2px 0.05em 1px 0px inset,
      rgb(120, 54, 252) -0.2px -0.05em 1px 0px inset !important;
  }
}
