// React vendors
@import "~socicon/css/socicon.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "~prism-themes/themes/prism-shades-of-purple.css";
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~animate.css/animate.css";

#root {
  display: contents;
}

.ql-editor {
  padding-bottom: 76px !important;
}

$color_1: var(--fg);

/* Dark theme  */
/* Animation */
@keyframes ring {
  from {
    stroke-dasharray: 0 257 0 0 1 0 0 258;
  }
  25% {
    stroke-dasharray: 0 0 0 0 257 0 258 0;
  }
  50%,
  to {
    stroke-dasharray: 0 0 0 0 0 515 0 0;
  }
}
@keyframes ball {
  from,
  50% {
    animation-timing-function: ease-in;
    stroke-dashoffset: 1;
  }
  64% {
    animation-timing-function: ease-in;
    stroke-dashoffset: -109;
  }
  78% {
    animation-timing-function: ease-in;
    stroke-dashoffset: -145;
  }
  92% {
    animation-timing-function: ease-in;
    stroke-dashoffset: -157;
  }
  57%,
  71%,
  85%,
  99%,
  to {
    animation-timing-function: ease-out;
    stroke-dashoffset: -163;
  }
}

.pl {
  display: inline-block;
  width: 36px;
  height: 36px;
}
.pl__ring {
  animation: ring 2s ease-out infinite;
}
.pl__ball {
  animation: ring 2s ease-out infinite;
  animation-name: ball;
}
